<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Conference</h1>
                <hr>
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                        <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                            <form @submit.prevent="postAnnouncement" class="row p-2">
                                <div class="col-xl-6 form-group mb-3">
                                    <label for="">Topic</label>
                                    <input v-model="form.topic" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-4 form-group mb-3">
                                    <label for="">Date & Time</label>
                                    <input v-model="form.start_time" type="datetime-local" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-2 form-group mb-3">
                                    <label for="">Cycle</label>
                                    <input v-model="form.cycle" type="text" required class="form-control text-grey-900 font-xsss fw-600">                        
                                </div>
                                <div class="col-xl-12 form-group mb-3">
                                    <label for="">Agenda</label>
                                    <textarea v-model="form.agenda" class="form-control text-grey-900 font-xsss fw-600"></textarea>
                                </div>
                                <div class="col-xl-12 form-group text-right my-4">
                                    <router-link to="/admin/courses" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                    <input type="Submit" class="btn btn-warning" value="Submit">
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
    </div>
</template>

<script>
import axios from 'axios'

export default {
    name: "ConferenceCreate",
    data(){
        return {
            paramsId: this.$route.params.idCourse,
            form: {
                topic: '',
                start_time: '',
                agenda: '',
                cycle: ''
            },
        }
    },
    methods:{
        async postAnnouncement() {
            var data = {
                topic : this.form.topic,
                start_time : this.form.start_time,
                agenda : this.form.agenda,
                course_id : this.paramsId,
                cycle: this.form.cycle
            }
            await axios.post(`${process.env.VUE_APP_URL_API}/admin/conference`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.token}`
                },
            }).then((res) => {
                this.$swal({
                    toast: true,
                    title: 'Success Create New Conference !',
                    text: res.data.message,
                    icon: 'success',
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                })
                this.$router.push({ name: 'ConferencesCoursesAdmin', params: {idCourse: this.paramsId} })
            }).catch((err) => {
                if (err.response?.status == 422){
                    this.$swal({
                        toast: true,
                        title: 'Failed Create New Conference !',
                        text: err.response.data.message[0],
                        icon: 'error',
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })
                }else{
                    console.log("GENERAL ERROR", err.response)
                }
            })
        },
    }
}
</script>
